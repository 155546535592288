<template>
    <div>
        <div>
            <el-button class="select-product-btn" size="mini" @click="dialogVisible = true" plain
                v-if="courses == '' & checkCourses == ''">选择课程</el-button>
            <div v-else class="select-course-box">
                <div v-for="(item, index) in courses" :key="item.id">
                    <div>{{ item.name }}</div>
                    <div>
                        <el-link type="primary" @click="delSelectCourse(item, index)">删除</el-link>
                    </div>
                </div>
                <div v-for="(item, index) in checkCourses" :key="item.id">
                    <div>{{ item.name }}</div>
                    <div>
                        <el-link type="primary" @click="delSelect(index)">删除</el-link>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="提示" :show-close="false" :visible.sync="dialogVisible" append-to-body width="70%"
            :before-close="handleClose">
            <div slot="title">
                选择课程
            </div>

            <div class="file-content " style="display: flex;justify-content: space-between">
                <div class="all-file">
                    <p class="file-tag " :class="type === 'checkCourse' ? 'selected' : ''"
                        @click="changeProduct('checkCourse')">阅读日程 <span class="file-count"></span> </p>
                    <p class="file-tag " :class="type === 'course' ? 'selected' : ''" @click="changeProduct('course')">
                        课程 <span class="file-count"></span> </p>
                </div>
                <div class="file-list-content">
                    <div style="padding-bottom: 10px">
                        <el-input class="fl-l" size="mini" style="width:200px;margin-right: 10px" placeholder="输入名称"
                            v-model="name" clearable>
                        </el-input>
                        <el-button class="fl-l" plain size="mini" @click="search">搜索</el-button>
                        <div class="clear"></div>
                    </div>

                    <div class="file-list" v-loading="loading">
                        <el-table size="mini " max-height="500" :header-cell-style="{ padding: 0 }" :cell-class-name="rowItem"
                            :row-key="getRowKeys" highlight-current-row @row-click="selectCourse"
                            :row-style="{ 'cursor': 'pointer' }" ref="multipleTable" :data="list" tooltip-effect="dark"
                            style="width: calc(100% - 300px)" @selection-change="handleSelectionChange">


                            <el-table-column prop="id" label="ID" width="50">
                            </el-table-column>
                            <el-table-column prop="name" label="图片" width="120">
                                <template slot-scope="scope">
                                    <el-image style="width: 100px; height: 60px" :src="imageUrl + scope.row.pic"
                                        fit="contain"></el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="名称" >
                            </el-table-column>
                        </el-table>
                        <div style="width:280px;padding:10px">
                            <div class="fl-l">
                                <p>选择的打卡课程</p>
                                <span style="display: block;height: 20px;"></span>
                                <p v-for="(item, index) in checkCourses" :key="index">
                                    <el-tag type="success" closable @close="close('checkCourses', index)">{{ item.name }}</el-tag>
                                    <span style="display: block;height: 5px;"></span>
                                </p>

                                <span style="display: block;height: 20px;"></span>
                                <p>选择的普通课程</p>
                                <span style="display: block;height: 20px;"></span>
                                <p v-for="(item, index) in courses" :key="index">
                                    <el-tag type="success" closable @close="close('courses', index)">普通课程ID{{ item.name
                                    }}</el-tag>
                                    <span style="display: block;height: 5px;"></span>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="clear"></div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel" size="mini">取 消</el-button>
                <el-button type="primary" size="mini" @click="select">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "selectUserCourse",
    props: ['id'],
    data() {
        return {
            imageUrl: config.imageUrl,
            dialogVisible: false,
            loading: true,
            list: [],
            total: 0,
            name: '',
            page: 1,
            pageSize: 10,
            selectCount: 0, //选择数量
            type: 'checkCourse',
            selectTableData: [],
            selectData: [], //已经选择的数据
            //普通课程
            courses: [],
            //打卡课程
            checkCourses: [],
        };
    },
    methods: {
        ...mapActions('sales', ['getUserCourseList', 'userCheckCourseList']),
        handleClose(done) {

        },
        //关闭
        close(type, index) {
            if (type == 'checkCourses') {
                this.checkCourses.splice(index, 1)
            } else {
                this.courses.splice(index, 1)
            }
        },
        search() {
            this.page = 1
            switch (this.type) {
                case 'course': this.getList(); break;
                case 'checkCourse': this.getCheckCourseList(); break;
            }
        },
        selectCourse(e) {
            if (this.type == 'checkCourse') {
                let index = this.checkCourses.findIndex(i => i.id == e.id)
                if (index == -1) {
                    this.checkCourses.push(e)
                }
            } else {
                let index = this.courses.findIndex(i => i.id == e.id)
                if (index == -1) {
                    this.courses.push(e)
                }
            }
        },
        delSelectCourse(index) {
            this.courses.splice(index, 1)
        },
        delSelect(index) {
            this.checkCourses.splice(index, 1)
        },
        changeProduct(type) {
            this.name = ''
            this.page = 1
            this.$refs.multipleTable.clearSelection()
            this.type = type
            switch (type) {
                case 'course': this.getList(); break;
                case 'checkCourse': this.getCheckCourseList(); break;
            }
        },
        getRowKeys(row) {
            return row.id;
        },
        setPage(e) {
            this.page = e
            switch (this.type) {
                case 'course': this.getList(); break;
                case 'checkCourse': this.getCheckCourseList(); break;
            }
        },
        handleSelectionChange(val) {
            this.selectTableData = val;
            this.selectCount = val.length
        },
        rowItem() {
            return 'row-item';
        },
        getList() {
            this.loading = true
            let data = {
                uid: this.id
            }
            this.getUserCourseList(data).then(res => {
                if (res.ret === 0) {
                    this.total = res.data.total
                    this.list = res.data.list
                }
                this.loading = false
            })
        },
        getCheckCourseList() {
            this.loading = true
            let data = {
                uid: this.id
            }
            this.userCheckCourseList(data).then(res => {
                if (res.ret === 0) {
                    this.total = res.data.total
                    this.list = res.data.list
                }
                this.loading = false
            })
        },
        cancel() {
            this.dialogVisible = false
        },
        select() {
            this.$emit('onSelect', {
                checkCourses: this.checkCourses,
                courses: this.courses
            })
            this.dialogVisible = false
        }
    },
    watch: {
        id(val) {
            this.courses = []
            this.checkCourses = []
            switch (this.type) {
                case 'course': this.getList(); break;
                case 'checkCourse': this.getCheckCourseList(); break;
            }
        }
    },
    mounted() {
        switch (this.type) {
            case 'course': this.getList(); break;
            case 'checkCourse': this.getCheckCourseList(); break;
        }
    }
}
</script>

<style scoped>
.page-info {
    padding: 10px 0;
    line-height: 26px;
}

.select-file-info {
    padding: 20px;
    background-color: #fafafa;
    width: 160px;
}

.select-file-info-name {
    width: 100%;
    color: #333;
    line-height: 20px;
    font-size: 12px;
    word-break: break-all
}

.el-divider__text,
.el-link {
    font-size: 12px;
}

.el-dialog__header {
    padding: 20px;
}

.el-dialog__body {
    padding: 0
}

.file-content {
    height: 550px;
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
}

.all-file {
    padding: 20px 10px;
    float: left;
    height: 510px;
    overflow-y: scroll;
}

.selected {
    background: #f7f7f7;
    color: #0095ff;
}

.file-tag {
    height: 32px;
    font-size: 12px;
    padding: 0 10px;
    line-height: 32px;
    cursor: pointer;
    margin-bottom: 10px;
}

.file-count {
    margin-left: 5px;
    font-size: 12px;
}

.search {
    width: 100%;
    flex-grow: 2;
    padding-bottom: 10px
}

.file-list {
    display: flex;
    justify-content: space-around;
}

.file-list-content {
    overflow: hidden;
    padding: 10px;
}

.el-table thead th .cell {
    text-indent: 5px
}

.el-card__body {
    padding: 10px
}

.select-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 30px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAadJREFUSInF1z1PFEEYAOAHEBIbf8FBQiF/gQIVExYj8Y6Y0HhGqSwEWwu1l2gvhsRGYiRobEDjBxhjZaGUYEzESKWVRqMFCeBZDAYT9nJ7y5J5y5mdeZ/dmZ13t8Vk7RquixStmMAl/IkFgNsYxWYsANzHCNZjAWAep/ArFgBeIcH3IhOd6OTnhWwAeIt+fC0ieVLiRYXLb7IDYBlH8XkvyQc7WRzmzAJ33jcHgE84gpShO/G8zLme3e0DJRYqVBd5sJo+thEAvuAYlupdMLfGvYTT3TttSYmXwyH57Mf6k7eYrGUwgEOYw/G0zpNdPCvT+4jfG6xUw2Ovd+d5AHAQD1FO60xKYb2h8pQna40nbBYA7ZhGNa3z7GE62rj7IdtkeQDQhlu4mGfw/5FlE6bFFsZxMxYAariCq7EA/+IGxuQs50UAYArnsRELADNylPMiAfAYQ5oo50UD4DUG8C0WAN7JWM73CwAr6NOgnO8nwHbyvm1MFABhGfqFr6woAMKGTIQNGgVAeDWHhFc1CoBwSI0I/yBRAITjelQ4vh2IACAUrjH8+Av5AmCiEP+iwQAAAABJRU5ErkJggg==);
}

.file-item {
    width: 260px;
    height: 72px;
    margin-bottom: 10px;
    border: 1px solid #ebebeb;
    cursor: pointer;
    position: relative;
    line-height: 1;
}

.file-icon {
    float: left;
    width: 50px;
    height: 50px;
}

.file-info {
    float: left;
    margin-left: 10px
}

.file-item-top {
    padding: 12px 10px 12px 12px;
}

.file-info-title {
    color: #333;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    width: 170px;
    height: 20px;
    overflow: hidden;
}

.file-info-size {
    color: #999;
    font-size: 12px;
}

.file-option {
    height: 36px;
    background: #fafafa;
    border-top: 1px solid #ebebeb;

}

.clear {
    clear: both;
}

.el-dialog__footer {
    padding: 20px
}

.select-course-box {
    background: #f1f1f1;
    padding: 10px;
}
</style>